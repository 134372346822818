.login-page {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    h2 {
        color:  #F0F6FA;;
    }
}

.login-footer{
    position: absolute;
    bottom: 20px;
    left: auto;
    right: 20px;
    a{  
        color: #ffffff;
        // text-decoration: none;
        font-size: 13px;
        

    }
}