.radial-bar-chart {
    .track {
        stroke: #434343b2;
    }
    .progress {
        cursor: pointer;
        transition: stroke-dashoffset 1.5s ease-in;
    }
    .niddle {
        transition: transform 2s ease-in; //cubic-bezier(0.47, 1.64, 0.41, 0.8);
    }
}
