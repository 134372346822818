.home-page {
  min-height: 100%;
  label.form-label {
    font-size: 10px;
    margin-bottom: 4px;
  }

  .progress-list {
    padding-left: 15px;
    .MuiFormGroup-root {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-right: 80px;
      position: relative;
    }

    .custom-bar {
      flex: 1;
      background-color: #373636;
      border-radius: 0;
      max-width: 170px;

      .progress {
        border-radius: 0;
        background-color: transparent;
        display: block;
        width: 100%;

        .progress-bar {
          height: 100%;
        }

        .mobile-score-span {
          white-space: normal !important;
        }
        span {
          position: absolute;
          right: 0;
          white-space: nowrap !important;
          width: 75px;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
    }
    &.active-item {
      background: rgba(41, 41, 41, 0.6);
    }
  }

  .container-fluid {
    padding: 0 40px;
  }

  .px-40 {
    padding: 0 40px;
  }

  .title {
    font-size: 20px;
    font-weight: 300;

    strong {
      font-weight: 400;
    }
  }

  .recomm-list {
    overflow: auto;
    max-height: 100px;
    flex-grow: 1;
    min-height: calc(100% - 50px);
    li {
      border-radius: 10px;
      background: #121212;
      padding: 15px 20px;
      margin-bottom: 15px;

      p {
        font-size: 14px;
        color: rgb(212 221 228 / 70%);
        margin-bottom: 0;
      }
    }
  }

  h5 {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }

  .action-box {
    height: 265px;
    border-radius: 10px;
    background: #2f2e2e;
    box-shadow: 2px 3px 6px 0px rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    line-height: 1.5;

    span {
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    svg.MuiSvgIcon-root {
      width: 60px;
      height: auto;
      margin-bottom: 20px;
    }
    a {
      text-decoration: none;
      color: #fff;
    }
  }

  .MuiLinearProgress-determinate {
    height: 16px;
    width: 170px;
    margin-left: auto;
  }

  .progress-list {
    border-bottom: 1px solid rgb(83 83 83 / 60%);
    padding-right: 10px;

    .MuiFormControlLabel-label {
      font-size: 14px;
    }

    &:last-child {
      border-bottom: 0;
    }

    .MuiRadio-root svg.MuiSvgIcon-root {
      width: 20px;
    }
  }

  .dount-text {
    position: absolute;
    top: auto;
    left: auto;
    font-size: 20px;
    small {
      font-size: 11px;
      font-weight: 600;
      max-width: 130px;
    }
    span {
      max-width: 130px;
    }
  }

  .border-bottom {
    border-bottom-color: #535353 !important;
  }

  .border-top {
    border-top-color: #535353 !important;
  }

  .border-start {
    border-left-color: #535353 !important;
  }

  .custom-bar p {
    font-size: 15px;
    color: #fff;
    margin-left: 10px;
  }

  label.legend {
    font-size: 13px;
    display: inline-flex;
    align-items: center;

    span {
      background: #59bd5d;
      width: 20px;
      display: inline-flex;
      height: 1px;
      margin-right: 10px;
      position: relative;

      &.score-legend:after {
        content: "";
        width: 8px;
        height: 8px;
        display: block;
        background-color: #ff9c80;
        border-radius: 60%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
      }
    }
  }

  .intro-list {
    li {
      font-size: 14px;
      display: inline-flex;
      margin-bottom: 10px;
      color: rgba(212, 221, 228, 0.7);

      &:before {
        content: "";
        background-color: #ff9c80;
        display: inline-flex;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        flex: 0 0 8px;
        margin-right: 10px;
        margin-top: 6px;
      }
    }
  }

  .left-sidebar {
    background: #000;
    box-shadow: 4px 0px 15px 0px rgba(0, 0, 0, 0.14);
  }

  .right-sidebar {
  }
  .graph-loader {
    min-height: 250px;
    .section-loader {
      background-color: #000;
    }
  }

  .small-text {
    font-size: 13px; /* Adjust the size as needed */
  }
}
