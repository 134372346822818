.BarChart {
  padding: 0 13%;

  > .MuiBox-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    flex: 1;

    .radial-bar-chart {
      margin: auto;
    }

    .MuiStack-root.labels {
      flex-direction: row;
      align-items: center;
      margin-bottom: 5px;

      span {
        width: 11px;
        height: 11px;
        background-color: #fff;
        border-radius: 10px;
        margin-right: 10px;
      }

      small {
        margin-left: auto;
        padding-left: 15px;
        font-size: 12px;
        width: 45px;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // text-align: right;
      }
    }
  }
}

.score {
  width: 82px;
  text-align: center;

  .score-status {
    width: 100%;
    height: 142px;
    background: #444;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid #de664f;

    strong {
      font-size: 30px;
      line-height: 1;
      font-weight: 500;

      small {
        font-size: 19px;
      }
    }
    > strong {
      display: none;
    }
    .total {
      height: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: linear-gradient(180deg, #df684a 0%, #ca498d 100%);
      font-size: 15px;
      font-weight: 500;
      transition: all 0.6s;
      strong {
        display: block;
      }
    }

    &.low-score {
      > strong {
        display: block;
      }
      .total {
        strong {
          display: none;
        }
      }
    }
  }

  span {
    color: #fff;
    font-size: 14px;
  }
}

.report-list {
  border-radius: 10px 10px 0px 0px;
  border: 3px solid #2f2e2e;
  background: #232222;
  padding: 18px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100svh - 390px);
  overflow: auto;
  .report-box {
    min-height: 100%;
    padding: 20px 40px;
    border-right: 2px solid #373636;
    border-bottom: 2px solid #373636;

    .report-top {
      margin-bottom: 16px;

      h6 {
        font-size: 14px;
        display: inline-flex;
        align-items: center;

        span {
          width: 10px;
          height: 17px;
          background-color: #ccc;
          margin-right: 10px;
          display: inline-flex;
        }
      }

      span {
        color: #ea765e;
        font-size: 23px;
        letter-spacing: 2.07px;
        font-weight: 300;
        margin-left: 5px;
        white-space: nowrap;
        small {
          color: #fff;
          font-size: 12px;
          letter-spacing: 1.08px;
          font-weight: 400;
        }
      }
    }

    p {
      color: #d4dde4;
      font-size: 14px;
      opacity: 0.7;
    }

    .compliance-flag {
      color: #ef7053;
      opacity: 1;
    }
  }
  .report-link {
    text-align: right;
    background-color: #232222;
    position: sticky;
    z-index: 1;
    padding-bottom: 10px;
    margin-top: -20px;
    padding-top: 16px;
    top: -20px;
    span {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 500;
      font-size: 12px;
      margin: 0 20px 0 auto;
    }
  }
  .col {
    &:nth-child(3n) {
      .report-box {
        border-right: 0;
      }
    }

    &:nth-child(4n),
    &:nth-child(5n),
    &:nth-child(6n) {
      .report-box {
        border-bottom: 0;
      }
    }
  }
}

.MuiFormControl-root {
  width: 100%;

  .MuiInputBase-root.MuiInputBase-formControl {
    font-size: 12px;
    background: #2f2e2e;
    border-radius: 0;
    .MuiSelect-select {
      padding: 6px 10px;
      min-height: inherit;
    }

    fieldset {
      display: none;
    }
  }
}

.top-select {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid #2b2929;
  padding-right: 10px;
  margin-right: 4px;
  span {
    font-size: 12px;
    white-space: nowrap;
    margin-right: 10px;
  }
}

.confirm-modal {
  .modal-content {
    border: 0;
    border-radius: 4px;
    background-color: #2f2e2e;
    padding: 16px;

    .modal-top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;

      h2 {
        font-size: 16px;
        line-height: 1;
        font-weight: 400;
      }

      button.MuiButtonBase-root.MuiButton-root {
        min-width: inherit;
        padding: 0;
        // svg{
        //     width: 20px;
        //     height: 20px;
        // }
      }
    }

    .modal-bottom {
      padding-top: 18px;
      border-top: 1px solid #525252;
      text-align: right;
      margin-top: 20px;
    }

    .content {
      margin: 20px 0;
      text-align: center;
    }
  }
}
