.loader {
    width: 55px;
    height: 15px;
    // background-color: #f1f0f0;
    margin: auto;
    border-radius: 15px;
    padding: 0 10px 8px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}
.loader .dot {
    display: inline-block;
    background-color: #90949c;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    animation: jump 1s linear infinite;
}
.loader .dot:nth-of-type(2) {
    animation-delay: 0.1s;
}
.loader .dot:nth-of-type(3) {
    animation-delay: 0.2s;
}
@keyframes jump {
    0% {
        transform: translateY(7px);
    }
    18% {
        transform: translateY(-3px);
    }
    36%,
    100% {
        transform: translateY(7px);
    }
}


.mic-button {
    position: relative;
    height: 40px;
    margin: auto !important;
    .processing {
        opacity: 0.8;
    }
    .pulse-ring {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: 2px;
        animation: pulsate infinite 2s;
        background: transparent;
        border: 1px solid #CD4E86;
    }
}
  
.pulse-ring.delay{
      animation-delay: 1s;
  }

  
@keyframes pulsate {
    0% {
      -webkit-transform: scale(1, 1);
      opacity: 1;
          
    }
    100% {
      -webkit-transform: scale(1.3, 1.3);
      opacity: 0;
    }
  }
