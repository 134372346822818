header {
    height: 45px;
    position: relative !important;

    .MuiTypography-h6 {
        font-size: 12px;
        color: #FF9C81;
        text-transform: uppercase;
    }
    .MuiToolbar-dense{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
        background-image: linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09));
        background-color: #121212;
    }

    .user-profile {
        border-left:1px solid #000 !important;
        button {
            padding-right: 0;
            &:hover {
                background-color: transparent;
            }

            .MuiTouchRipple-root {
                display: none;
            }
            svg{
                width: 17px;
                height: auto;
            }
        }

        p.MuiTypography-root {
            font-size: 14px;
            font-weight: 300;
            margin-left: 10px;
        }
    }
    button.logo-btn{
        padding: 0;
        margin-right: 10px;
        svg{
            width: 38px;
            height: auto;
        }
    }
}

.MuiMenu-paper {
    .MuiMenu-list {
        li {
            font-size: 12px;
            min-height: inherit;
        }   
    }
}
#menu-appbar{
    .MuiMenu-paper {
        min-width: 120px !important;
    }
}