@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
.MuiDivider-root {
  height: 2px;
  background: #3E3E3E80;
}

.MuiButton-text {
  color: #F0F6FA !important;
  text-transform: none !important
}
body,.MuiTypography-root{
  font-family: 'Roboto', sans-serif !important;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background: #696868;
  border-radius: 7px;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: #696868;
  border-radius: 7px

}

::-webkit-scrollbar-track {
  background: #505050;
}

