.new-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .step-container {
    padding: 10px 40px;

    .page-title {
      font-size: 16px;
      font-weight: 300;
      color: #f0f6fa;
    }

    button.back-link {
      padding: 0;
      line-height: initial;
      font-size: 14px;
      color: #f0f6fa !important;
      opacity: 0.6;
      font-weight: 300;
      background-color: transparent;
      min-width: inherit;

      svg {
        line-height: 1;
        width: 15px;
        height: auto;

        path {
          fill: #f0f6fa;
        }
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }

    span.MuiStepLabel-label {
      font-size: 14px;
      font-weight: 300;
      color: #fdfdfd;

      &.Mui-active {
        font-weight: 500;
      }
    }
  }

  .step-icon {
    z-index: 1;
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: #3e3e3e;

    &.active {
      background: linear-gradient(148.39deg, #df6945 15.32%, #c84693 86.45%);

      svg {
        path {
          fill: #fdfdfd;
        }
      }
    }

    svg {
      width: 20px;

      path {
        fill: rgb(205 205 205 / 80%);
      }
    }
  }

  .MuiStepConnector-root {
    .MuiStepConnector-line {
      height: 3px !important;
    }
  }

  .option-card {
    cursor: pointer;
    background: #313030 !important;
    border: 1px solid #343e4a;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: all 0.3s !important;
    padding: 10px 15px !important;
    margin: 0 16px 0px 0;

    &.active,
    &:hover {
      border: 2px solid #c9488f;
      background-color: #000000 !important;

      p {
        font-size: 14px;
        color: #fff;
      }
    }

    &.active {
      .profile {
        &:after {
          // width: 0px;
          // height: 0px;
          // border-left: 7px solid transparent;
          // border-right: 7px solid transparent;
          // border-top: 7px solid #c9488f;
          // content: "";
          // display: block;
          // position: absolute;
          // bottom: -8px;
          // left: 0;
          // right: 0;
          // margin: auto;
        }
      }

      .profile-content {
        display: flex;
      }

      // &:first-child {
      .profile-content {
        left: 0;
      }

      // }

      &:last-child {
        .profile-content {
          right: 0;
          left: auto;
        }
      }
    }

    .check {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 17px;
      height: 17px;
      background-color: #000;
    }

    p {
      font-size: 14px !important;
      color: #c0c9d3;
      font-weight: 400;
    }
  }

  .option-list {
    padding: 20px 40px 30px;

    h6 {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 15px;
    }

    .text-start {
      h6 {
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100px;
      }
    }
  }

  .step-button {
    display: flex;
    padding: 30px 20px;
    justify-content: space-between;
  }

  .steps-middle {
    overflow: auto;
    max-height: calc(100svh - 282px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  p,
  small {
    font-size: 13px !important;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0;
  }

  .profile-img {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    border-radius: 50%;
    background-color: #f1f1f1;
    overflow: hidden;
    margin-right: 15px;
    img {
      height: 100%;
    }
  }

  .profile-content-main {
    border-radius: 4px;
    border: 1px solid #c9488f;
    background: #000;
    padding: 40px;
    margin-top: 10px;
    text-align: left;
    height: 90%;
    .profile-img {
      margin-bottom: 10px;
      width: 115px;
      height: 115px;
      flex: 0 0 115px;
    }
  }

  .profile-content {
    display: none;
    border-radius: 4px;
    border: 1px solid #c9488f;
    background: #000;
    padding: 40px;
    width: 650px;
    margin-top: 20px;
    position: absolute;
    bottom: 0px;
    top: 100%;
    text-align: left;
    height: fit-content;
    left: auto;
    z-index: 2;

    .profile-img {
      width: 115px;
      height: 115px;
      flex: 0 0 115px;
    }
  }
}

button.MuiButtonBase-root.MuiButton-root {
  color: #fff;
  text-transform: capitalize;
  border-radius: 4px;
  line-height: 1.6;
  font-size: 13px;
  letter-spacing: 1px;

  &.MuiButton-contained.MuiButton-containedPrimary {
    background: linear-gradient(97deg, #df6946 4.88%, #ca498d 90.32%);
  }

  &.MuiButton-outlined.MuiButton-outlinedPrimary {
    flex-shrink: 0;
    border: 2px solid #c94890;
    min-width: 90px;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
}

.mobile-view {
  display: none !important;
}

// responsive start
@media only screen and (min-width: 766px) and (max-width: 1100px) {
  body {
    .home-page {
      .container-fluid,
      .px-40 {
        padding: 0 10px;
      }

      .progress-list {
        padding-right: 10px;
        padding-bottom: 10px;

        .MuiFormControlLabel-label {
          font-size: 12px;
          line-height: 1.2;
        }
      }
    }

    .BarChart {
      padding: 0 2%;
    }

    .chat-window {
      .window-content {
        h2 {
          font-size: 30px;
        }

        small {
          font-size: 14px;
        }

        button {
          font-size: 13px;
        }
      }
    }
  }
}

@media only screen and (min-width: 766px) and (max-width: 900px) {
  body {
    .home-page {
      .progress-list .MuiFormGroup-root label {
        width: 100%;
      }

      .container-fluid {
        padding: 0 10px;
      }
    }

    .home-right .col-6 {
      width: 100%;
    }

    .BarChart {
      padding: 0;

      > .MuiBox-root {
        flex-direction: column;

        .radial-bar-chart svg {
          width: 210px;
        }
      }
    }

    .chat-window .window-top h6 {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 250px;
    }
  }
}

@media only screen and (max-width: 765px) {
  body {
    header .user-profile p.MuiTypography-root {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 80px;
      overflow: hidden;
    }

    .confirm-modal .modal-content {
      width: 300px;

      p {
        font-size: 14px;
      }
    }

    header button.logo-btn {
      margin-left: 0;
    }

    .page-title {
      min-height: 40px;
      font-size: 10px;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
      border-bottom: 1px solid #353537;
      letter-spacing: 1.5px;
      z-index: 111;
      position: sticky;
      top: 47px;
      background-color: #202020;
      display: flex;
      text-transform: uppercase;

      button {
        position: absolute;
        left: 10px;
        min-width: inherit;

        span {
          margin-left: 0;
        }

        svg {
          width: 16px;
          height: auto;
        }

        &.new-btn {
          right: 0;
          left: auto;
          font-size: 11px;
          line-height: 1.2;
          padding: 0;
          letter-spacing: normal;

          .MuiButton-iconSizeMedium {
            margin-left: 6px;
            margin-right: 10px;
          }
        }
      }
    }

    .MuiFormControl-root .MuiInputBase-root.MuiInputBase-formControl {
      max-width: 320px;
    }

    .mobile-view {
      display: inline-flex !important;
    }

    .main-view {
      display: none !important;
    }

    .home-page {
      min-height: inherit;

      .home-right {
        width: 100%;

        .col-6:first-child {
          border-right: 1px solid #353537;
          margin-bottom: 15px;
        }

        .action-box {
          height: 100px;
          margin-bottom: 0;

          span {
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            line-height: 1.2;

            svg {
              width: 27px;
              height: auto;
              margin-bottom: 10px;
            }
          }
        }
      }

      .graphBox {
        overflow: auto;
      }

      .graph-select {
        background: #2f2e2e80;
        padding: 10px;
        border-radius: 10px;
        margin-top: 20px;
      }

      .progress-list {
        padding: 5px 10px;

        .custom-bar {
          width: 100%;
          flex: 0 42%;
          height: 13px;
        }

        .MuiFormGroup-root {
          flex-wrap: nowrap;

          label {
            flex: 1;
            font-size: 12px;
          }

          .custom-bar {
            flex: 0 40%;
            background-color: #373636;
            border-radius: 0;
            max-width: 100%;
          }
        }
      }
    }

    .dashboard-middle {
      margin: 0 -10px;
      padding: 10px;
      background-color: #000;
    }

    .top-filter {
      width: 100%;
      padding: 0 10px 10px;
      border-bottom: 1px solid #353537;

      > div {
        flex: 1;
        margin-right: 10px;
        max-width: 200px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .chat-screen {
      flex-direction: column;

      .MuiGrid-item {
        width: 100%;
        min-height: 100%;

        .chat-leftbar {
          width: 100%;
          padding: 0;

          .chat-item {
            border-radius: 36px;

            &:hover > div,
            &.active > div {
              border-radius: 36px;
            }

            .right-text {
              display: flex;
              align-items: center;

              .delete {
                position: relative;
                opacity: 1;
                padding: 4px;
                margin-left: 6px;
                margin-right: -8px;
                right: 0;

                svg {
                  width: 19px;
                  height: auto;
                }
              }
            }
          }

          .top-chat {
            font-size: 14px;
            padding: 10px 0;
            height: 40px;
            border-bottom: 1px solid #353537;
            background-color: #202020;
            position: sticky;
            top: 87px;
            z-index: 111;

            .MuiGrid-container {
              display: flex;
              flex-wrap: nowrap;
              align-items: center;
              justify-content: center;
              height: 40px;

              .MuiGrid-item {
                width: auto;
                min-height: auto;
              }
            }
          }

          .chat-container {
            margin: 0;
            min-height: calc(100% - 130px);
          }

          .top-select {
            padding: 10px 10px;
            margin-right: 0;
            position: sticky;
            top: 127px;
            background-color: #000;
            z-index: 111;
            margin-bottom: 0;

            span {
              color: #f0f6fa;
            }
          }

          .MuiAccordion-root {
            .MuiAccordionSummary-root {
              margin-right: 0;
              padding: 0 10px;
            }

            .MuiAccordionDetails-root {
              padding: 0 10px;
              margin-bottom: 10px;
            }

            &::after {
              width: 100%;
            }
          }

          .page-title {
            padding-left: 40px;
            justify-content: flex-start;
          }
        }
      }
    }

    .profile-text {
      width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .chat-window {
      padding: 12px 0;

      .top-switch {
        font-size: 14px;
        padding: 10px 0;
        height: 40px;
        border-bottom: 1px solid #353537;
        background-color: #202020;
        position: sticky;
        top: 0;
        z-index: 111;
        margin-right: 0;
      }

      .window-middle {
        background-color: transparent;
        overflow-y: auto;
        overflow-x: hidden;
        section.p-3.d-flex.flex-column.flex-grow-1 {
          padding: 0 !important;
        }
      }

      .chatting-window {
        padding: 20px 20px 0 20px;
        // max-height: calc(100svh - 208px);
        margin-bottom: 0 !important;

        .chat {
          margin-bottom: 20px;

          .content {
            p {
              font-size: 12px;
              line-height: 1.6;
              color: #d4dde4;
            }

            .text {
              padding: 12px 15px;
            }
          }

          &.chat-right {
            .content {
              margin-right: 0;
            }
          }

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }

      .top-switch {
        .MuiGrid-item {
          width: auto;
          min-height: auto;
          padding: 0 5px;
        }

        .MuiGrid-container {
          margin-right: auto;
        }
      }

      .page-title {
        padding-left: 32px;
        justify-content: flex-start;
      }
    }
    .chat-window .top-switch + .flex-grow-1.h-100 {
      max-height: inherit;
    }
    .chat-item {
      border-radius: 25px !important;
      margin-bottom: 10px !important;

      .MuiChip-root.MuiChip-filled {
        display: none;
      }

      > div {
        height: 48px !important;
      }

      small {
        margin-bottom: 0 !important;
      }
    }

    .headphone-loader {
      width: 25px;
      height: 25px;
    }

    .new-wrapper {
      height: calc(100svh - 45px);
      overflow: hidden;
      .steps-middle {
        flex: 1;
        max-height: calc(100svh - 274px);
      }
      .option-list {
        padding: 20px 20px 0;
        flex-grow: 1;
        overflow: auto;

        h6 {
          display: none;
        }

        > .MuiBox-root {
          flex-wrap: wrap;
          margin: 0 -10px;

          .option-card {
            flex: 1;
            margin: 0 10px 20px;
            min-width: calc(50% - 20px);
          }
        }

        &.customer-list {
          > .MuiBox-root {
            flex-direction: column;

            .option-card {
              min-width: calc(100% - 20px);
              justify-content: space-between;
              background-color: #2f2e2e !important;
              margin-right: 0;
              width: auto;
              margin-left: 0;
              border-radius: 25px;

              .check {
                display: none;
              }

              .profile-img {
                width: 32px;
                height: 32px;
                flex: 0 0 32px;
                margin-right: 10px;
              }

              .profile {
                align-items: center;
                line-height: 1.3;

                &:after {
                  border: solid #8f8f8f;
                  border-width: 0 3px 3px 0;
                  display: inline-block;
                  padding: 3.7px;
                  bottom: auto;
                  left: auto;
                  right: 20px;
                  transform: rotate(45deg);
                  -webkit-transform: rotate(45deg);
                  content: "";
                  display: block;
                  position: absolute;
                }

                h6 {
                  display: block;
                  width: auto;
                }
              }

              &.active {
                .profile {
                  &:after {
                    transform: rotate(228deg);
                    -webkit-transform: rotate(228deg);
                  }
                }
              }
            }

            .profile-content {
              position: relative;
              width: 100%;
              border: 0;
              padding: 0;
              margin-top: 10px;
              background-color: #2f2e2e;

              .profile-img {
                display: none;
              }

              .content-text {
                h6,
                small {
                  display: none !important;
                }

                p {
                  font-size: 13px !important;
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
      .step-button {
        padding: 20px;
      }
      .step-container {
        padding: 10px 0 !important;

        .page-title {
          border-bottom: 1px solid #353537;
          padding: 0 15px 10px;
          display: flex;
          font-size: 10px;
          font-weight: 400;
          color: #fff;
          top: 0;
          .back-link {
            font-weight: 400;
            opacity: 1;
          }
        }

        .MuiStepper-alternativeLabel {
          min-width: 100% !important;
        }
      }
    }

    .BarChart {
      padding: 0;
      flex-direction: column;

      > .MuiBox-root {
        flex-direction: column;
      }
    }

    .score {
      display: flex;
      width: auto;
      text-align: center;
      flex-direction: row-reverse;
      align-items: center;
      margin-top: 10px;

      .score-status {
        width: 100%;
        height: auto;
        text-align: center;
        border: 0;
        background: linear-gradient(180deg, #dd6553 0%, #cd4e86 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        .total {
          height: auto !important;
          background: transparent !important;
        }
      }

      span {
        white-space: nowrap;
        margin-right: 10px;
      }
    }

    .report-list {
      border-radius: 0;
      border: 0;
      background: transparent;
      padding: 0 10px;
      height: auto;

      .report-box {
        padding: 0 0 10px;
        border: 0;

        .report-top {
          margin-bottom: 16px;
          border-bottom: 1px solid #3d3b3b;
          padding-bottom: 5px;

          span {
            font-size: 18px;
            letter-spacing: 0;
          }

          h6 {
            font-size: 12px;

            span {
              width: 10px;
              height: 10px;
              margin-right: 7px;
              border-radius: 11px;
            }
          }
        }

        p {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }
    }

    .chatbar {
      margin: 0 15px;
    }
  }
}
