.chat-leftbar {
  width: 300px;
  background-color: #000;
  padding: 10px 8px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top-chat {
    font-size: 14px;
    padding: 10px 12px 15px;

    .MuiGrid-item {
      padding-top: 0;
      line-height: 1;
    }
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: calc(100% - 120px);
    max-height: 100px;
    margin-right: -9px;
  }

  .MuiAccordion-root {
    .MuiTypography-root {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2;
      color: #dcdcdc;
    }

    .MuiAccordionSummary-root {
      background-color: #000;
      min-height: inherit;
      padding: 0 12px;
      position: sticky;
      top: 0;
      z-index: 11;
      margin-right: 20px;
      &.Mui-expanded {
        margin-right: 2px;
      }
    }

    .MuiAccordionDetails-root {
      padding: 5px 0 16px;
    }
    &:after {
      content: "";
      display: block;
      width: calc(100% - 21px);
      height: 1px;
      background-color: #2b2929;
      position: absolute;
      bottom: -1px;
      left: 0;
      z-index: 22;
    }
  }

  .chat-item {
    margin-top: 0;
    margin-bottom: 22px;
    line-height: 1.2;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    padding: 1px;
    transition: all 0.3s;
    border-radius: 36px 0 0 36px;

    .difficulty {
      display: inline-flex;
    }
    .personality_type {
      display: inline-flex;
    }

    .delete {
      opacity: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 7px;
      background: linear-gradient(
        269deg,
        #2f2e2e 49.96%,
        rgba(47, 46, 46, 0) 109.03%
      );
      padding-left: 40px;
      transition: all 0.7s ease-out;

      .MuiTouchRipple-root {
        display: none;
      }
    }

    &:hover {
      .delete {
        opacity: 1;
      }
    }

    > div {
      padding: 8px 15px 8px 10px;
      background: #2f2e2e;
      height: 75px;
      position: relative;
      z-index: 2;
      border-radius: 36px 0 0 36px;
    }

    &.active,
    &:hover {
      border-radius: 36px 0 0 36px;

      &:after {
        background: linear-gradient(180deg, #df684a 0%, #ca498d 100%);
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        display: block;
        height: 100%;
        z-index: 1;
        top: 0;
        bottom: 0;
      }

      > div {
        border-radius: 36px 0 0 36px;
      }
    }

    small {
      font-size: 12px;
      font-weight: 300;
      margin: 5px 0 8px;
    }

    .title {
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
      color: #fff;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .chat-date {
      font-size: 10px;
      display: flex;
      flex-direction: column;
      text-align: right;

      i {
        font-weight: 300;
        font-style: normal;
        margin-bottom: 3px;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  .MuiChip-root.MuiChip-filled {
    height: 19px;
    background: #111111;
    font-size: 11px;
  }
}

.MuiSwitch-root {
  width: 30px !important;
  height: 16px !important;

  .MuiSwitch-switchBase {
    padding: 1px;
    margin: 0;

    &.Mui-checked {
      transform: translateX(14px);
    }
  }
}

.page-loader {
  position: fixed;

  &.section-loader {
    position: absolute;
  }

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(47, 46, 46, 0.7);
  z-index: 1399;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiCircularProgress-root {
    width: 50px !important;
    height: 50px !important;
    color: #cacaca;
  }
}
