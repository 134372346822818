.chat-screen {
  height: calc(100svh - 45px);
}
.addIcon-btn{
    background-image: url("../../../../assets/images/add-icon.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 20px;
    padding-right: 30px !important;
}
.add-icon{
    background-image: url("../../../../assets/images/add-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}
.chat-window {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 12px 20px;

  .window-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    h6 {
      font-size: 14px;
      color: #f0f6fa;
      span {
        color: #f39782;
        font-size: 12px;
      }
    }

    button.MuiButtonBase-root.MuiButton-root {
      font-size: 14px;
      font-weight: 300;
      padding: 0;
      background-color: transparent;
    }
  }

  .window-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
      font-size: 50px;
      font-weight: 300;
    }

    small {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 20px;
      text-align: center;
    }

    button {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      font-weight: 400;
      background-color: transparent;
      padding: 0;

      svg {
        height: 50px;
        width: 50px;
      }
    }
  }

  .window-middle {
    border-radius: 10px;
    background: #2f2e2e;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100svh - 110px);

    .MuiInputBase-root {
      border-radius: 8px;
      border: 1px solid #2f2e2e;
      background: #121111;
      font-size: 15px;
      height: 40px;
      padding-right: 18px;

      fieldset {
        border: 0;
      }

      button {
        padding: 0;
        margin: 0;
      }
    }
  }

  .chatting-window {
    padding: 30px 20px 10px;
    overflow: auto;
    flex: 1 1;
    .chat {
      display: flex;
      margin-bottom: 25px;
      position: relative;
      .content {
        border-radius: 0px 16px 16px 16px;
        border: 1px solid #ff9c80;
        background: #2b2b2d;
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.2);
        max-width: 800px;
        margin-left: 15px;
        // min-width: 200px;
        // min-height: 70px;
        position: relative;
        p {
          font-weight: 400;
          font-size: 13px;
          line-height: 2;
          color: #d4dde4;
        }

        strong {
          font-weight: 500;
          color: #fff;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          margin: -1px;
          border-radius: inherit;
          background: linear-gradient(
            0deg,
            rgb(0 0 0) 32%,
            rgba(255, 156, 128, 1) 100%
          );
        }
        .text {
          background-color: #2b2b2d;
          position: relative;
          z-index: 2;
          padding: 12px 20px;
          border-radius: 0px 16px 16px 16px;
          .headphone {
            cursor: pointer;
            &:hover {
              transform: scale(1.1);
              box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
              border-radius: 100%;
              transition: all 0.3s;
            }
          }
        }
      }

      svg {
        width: 30px;
        height: 30px;
        margin-left: 10px;
      }
      .text {
        display: flex;
      }
      &.chat-right {
        flex-direction: row-reverse;

        .content {
          margin-right: 15px;
          border-radius: 16px 0 16px 16px;
          border: 1px solid #ce4f83;
          background: #252526;
          box-shadow: -3px 3px 5px 0px rgba(0, 0, 0, 0.2);

          &::after {
            background: linear-gradient(
              0deg,
              rgb(34 34 34) 25%,
              rgba(206, 79, 131, 1) 100%
            );
          }

          .text {
            margin-right: auto;
            border-radius: 16px 0 16px 16px;
            background: #252526;
          }
        }

        // svg {
        //     width: 18px;
        //     height: 18px;
        // }
      }
    }
  }

  .bottom-btn {
    padding: 10px 20px;
    text-align: right;
  }
  .top-switch {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    margin-right: 15px;
    .MuiGrid-container {
      margin-left: auto;
      width: auto;
    }
  }
}
.chat-window .top-switch + .flex-grow-1.h-100 {
    max-height: calc(100% - 60px);
}
.chatbar {
  border-radius: 8px;
  border: 1px solid #2f2e2e;
  background: #121111;
  min-height: 40px;
  padding: 2px 0px 2px 2px;
  .chat-area {
    display: flex;
    align-items: center;
    flex: 1;
    // padding-left: 5px;
    textarea {
      width: 100%;
      background-color: transparent;
      color: #ccc;
      border: 0 !important;
      outline: none !important;
      max-height: 160px;
      &:focus {
        color: #ccc;
        font-weight: 300;
      }
    }
  }
}

.mic-loader {
  border: 2px solid #5e5e5e;
  border-radius: 50%;
  border-top: 2px solid #ca498d;
  width: 30px;
  height: 30px;
  -webkit-animation: mic-spin 2s linear infinite; /* Safari */
  animation: mic-spin 2s linear infinite;
  position: absolute;
}

/* Safari */
@-webkit-keyframes mic-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes mic-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headphone-loader {
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  &::after {
    -webkit-animation: mic-spin 2s linear infinite; /* Safari */
    animation: mic-spin 2s linear infinite;
    border: 2px solid #5e5e5e;
    border-radius: 50%;
    border-top: 2px solid #ca498d;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "";
    display: block;
    z-index: 22;
  }
  svg {
    margin-left: 0 !important;
  }
}
